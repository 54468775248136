import '../fake-db';
import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updatePageMetadata } from './utils';
import { useEffect } from 'react';

const App = () => {
  const content = useRoutes(routes);
  console.log("TEST-01");
  useEffect(() => {
    // Update favicon and title when component mounts
    const currentOrigin= window.location.origin;
    if(currentOrigin.includes('helpdesk.gleren')){
      updatePageMetadata('glerenIcon.ico', 'Helpdesk Gleren');
    }else if(currentOrigin.includes('backoffice.aturserv')){
      updatePageMetadata('aturserv.ico', 'Back Office Aturserv');
    }else if(currentOrigin.includes('localhost')){
      updatePageMetadata('glerenIcon.ico', 'Helpdesk');
    }

  }, []);
  return (
    <Provider store={Store}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>{content}</AuthProvider>
        </MatxTheme>
      </SettingsProvider>
    </Provider>
  );
};

export default App;

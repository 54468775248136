import React, { lazy } from 'react'
import Analytics from './analytics'
const analyticsRoutes = [
    {
        path: '/analytics',
        element: <Analytics></Analytics>
    }
]

export default analyticsRoutes

import { appRequest, RequestMethod } from "../../app/httpClient/request";
import config from "../../config/apiConfig.json";
const BASE_URL = config.general.baseUrl;

export const loginUser = (formData) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.login,
    data: formData,
  });
};
export const registerTenant = (formData) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.register,
    data: formData,
  });
};
export const registerEmployee = (formData) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.registerEmployee,
    data: formData,
  });
};
export const sendOTPEmail = (formData) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.generateOTPEmail,
    data: formData,
  });
};
export const sendLoginOTPEmail = (formData) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.generateLoginOTPEmail,
    data: formData,
  });
};
export const verifyOTPEmail = (formData) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.verifyOTPEmail,
    data: formData,
  });
};
export const getLoggedInUserDetails = () => {
  return appRequest({
    method: RequestMethod.GET,
    url: BASE_URL + config.user.getLoggedInUserDetails,
  });
};
export const updateUserProfile = (data) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.updateUserProfile,
    data: data,
  });
};
export const verifyEmail = (data) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.verifyEmail,
    data: data,
  });
};

export const sendVerifyEmailOTP = (formData) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.generateVerifyEmailOTP,
    data: formData,
  });
};

export const getAllUsers = (page, size) => {
  return appRequest({
    method: RequestMethod.GET,
    url: BASE_URL + config.user.getAllUsers + `?page=${page}&size=${size}`,
  });
};
export const getUserById = (data) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.getUserById,
    data: data,
  });
};
export const createUpdateUser = (data) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.createUpdateUser,
    data: data,
  });
};

export const getProfilePic = () => {
  return appRequest({
    method: RequestMethod.GET,
    url: BASE_URL + config.user.getProfileURL,
  });
};

export const getAllDomainTypes = () => {
  return appRequest({
    method: RequestMethod.GET,
    url: BASE_URL + config.domainType.getAllDomainTypes,
  });
};

export const getAllLinkedAccounts = (formdata) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.getAllLinkedAccounts,
    data: formdata,
  });
};

export const createLinkedAccount = (formdata) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.createLinkedAccount,
    data: formdata,
  });
};

export const deleteLinkedAccount = (formdata) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.deleteLinkedAccount,
    data: formdata,
  });
};

export const switchAccount = (formdata) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.switchAccount,
    data: formdata,
  });
};

export const getUserIdByEmail = (formdata) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.getUserIdByEmail,
    data: formdata,
  });
};

export const sendAccountLinkOTPEmail = (formData) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.user.generateAccountLinkOTPEmail,
    data: formData,
  });
};

export const getAllUser = () => {
  return appRequest({
    method: RequestMethod.GET,
    url: BASE_URL + config.user.getAllUser,
  });
};

export const getAllUsersByTenant = () => {
  return appRequest({
    method: RequestMethod.GET,
    url: BASE_URL + config.user.getAllUsersByTenant,
  });
};

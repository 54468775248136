import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable'
const ReimbursementApproveView = Loadable(lazy(() => import('./reimbursementApprove')))

const ReimbursementApproveRoutes = [
    {
        path: '/reimbursementApprove',
        element: <ReimbursementApproveView />,
    },
]

export default ReimbursementApproveRoutes;
import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable'
import { authRoles } from '../../auth/authRoles'
const LinkedAccountView = Loadable(lazy(() => import('./linkedAccountView')))


const linkedAccountRoutes = [
    {
        path: '/linkedAccounts',
        element: <LinkedAccountView />
    },
]

export default linkedAccountRoutes;

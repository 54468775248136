import React, { lazy } from "react";
import Loadable from "app/components/Loadable";
import { authRoles } from "../../auth/authRoles";
const EmployeeCompOffs = Loadable(lazy(() => import("./EmployeeCompOffs")));

const EmployeeCompOffsRoutes = [
  {
    path: "/employeeCompOffs",
    element: <EmployeeCompOffs />,
    auth: authRoles.admin,
  },
];

export default EmployeeCompOffsRoutes;

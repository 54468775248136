export const MenuProps = {
    PaperProps: {
        onScroll: () => {
        }
    },
    style: { maxHeight: 300 },
    id: "id-menu",
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    getContentAnchorEl: null
}
export const updatePageMetadata = (faviconPath, title) => {
    const favicon = document.getElementById('favicon');
    if (favicon) {
        console.log(window.location.origin);
        favicon.href = window.location.origin + '/' + faviconPath;
    }

    document.title = title;
};

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable'
import ViewReimbursement from '../reimbursementApprove/viewReimbursement'
const ReimbursementView = Loadable(lazy(() => import('./reimbursementView')))


const ReimbursementRoutes = [
    {
        path: '/reimbursement',
        element: <ReimbursementView/>
    },
    {
        path: '/view-reimbursement/*',
        element: <ViewReimbursement></ViewReimbursement>
    }
]

export default ReimbursementRoutes

import AuthGuard from "app/auth/AuthGuard";
import chartsRoute from "app/views/charts/ChartsRoute";
import dashboardRoutes from "app/views/dashboard/DashboardRoutes";
import materialRoutes from "app/views/material-kit/MaterialRoutes";
import NotFound from "app/views/sessions/NotFound";
import sessionRoutes from "app/views/sessions/SessionRoutes";
import { Navigate } from "react-router-dom";
import MatxLayout from "./components/MatxLayout/MatxLayout";
import bugReportRoutes from "./views/bugReport/bugReportRoutes";
import departmentRoutes from "./views/Departments/departmentRoutes";
import escalationRoutes from "./views/escalations/escalationRoutes";
import myProfileRoutes from "./views/myProfile/myProfileRoutes";
import projectRoutes from "./views/projects/projectRoutes";
import statusRoutes from "./views/status/statusRoutes";
import FixedVersionRoutes from "./views/Fixed Version/FixedVersionRoutes";
import teamRoutes from "./views/teams/teamRoutes";
import ticketRoutes from "./views/tickets/ticketRoutes";
import userRoutes from "./views/users/userRoutes";
import holidayRoutes from "./views/holidays/holidayRoutes";
import linkedAccountRoutes from "./views/linkedAccounts/linkedAccountRoutes";
import tenantSettingsRoutes from "./views/Tenant Settings/tenantSettingsRoutes";
import leaveRoutes from "./views/leave/leaveRoutes";
import leaveApproveRoutes from "./views/leaveApprove/leaveApproveRoutes";
import leaveManagementRoutes from "./views/leaveManagement/leaveManagementRoutes";
import ReimbursementRoutes from "./views/reimbursement/reimbursementRoutes";
import ReimbursementApproveRoutes from "./views/reimbursementApprove/reimbursementApproveRoutes";
import EmployeeLeavesRoutes from "./views/EmployeeLeaves/EmployeeLeavesRoutes";
import EmployeeCompOffsRoutes from "./views/EmployeeCompOffs/EmployeeCompOffsRoutes";
import analyticsRoutes from "./views/analytics/analyticsRoutes";
const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),

    children: [
      ...dashboardRoutes,
      ...chartsRoute,
      ...materialRoutes,
      ...projectRoutes,
      ...bugReportRoutes,
      ...myProfileRoutes,
      ...statusRoutes,
      ...FixedVersionRoutes,
      ...userRoutes,
      ...departmentRoutes,
      ...escalationRoutes,
      ...teamRoutes,
      ...ticketRoutes,
      ...linkedAccountRoutes,
      ...holidayRoutes,
      ...tenantSettingsRoutes,
      ...leaveRoutes,
      ...leaveApproveRoutes,
      ...leaveManagementRoutes,
      ...ReimbursementRoutes,
      ...ReimbursementApproveRoutes,
      ...EmployeeLeavesRoutes,
      ...EmployeeCompOffsRoutes,
      ...analyticsRoutes
    ],
  },
  ...sessionRoutes,
  { path: "/", element: <Navigate to="dashboard/default" /> },
  { path: "*", element: <NotFound /> },
];

export default routes;

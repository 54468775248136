import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router";
import moment from "moment";
import { Formik } from "formik";
import "./ticketsList.css";
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import * as Yup from 'yup';
import { Strings } from "config/strings";
import { authRoles } from "app/auth/authRoles";
import { getEscalationByDepartment, getMasterDropdownData,getStatusByDepartment } from "app/services/adminService";
import {
  createTicket,
  deleteFile,
  fileUpload,
  getFixVersionByProject,
  getSignedUrl,
} from "app/services/ticketService";
import CircularProgress from "../../components/MatxLoading";
import {getStatusByDepId} from "../../utils/utils";
import '../../style.css'
import { toast } from "react-toastify";
import { MenuProps } from "app/utils";
import "trix/dist/trix";
import "trix/dist/trix.css";
import { ReactTrixRTEInput ,ReactTrixRTEToolbar } from "react-trix-rte";
import ReactImageMagnify from "react-image-magnify";


const CreateTicket = ({ onClose }) => {
  const [valid, setValid] = React.useState(false);
  const handleClose = (event) => !!onClose && onClose(event) && setValid(false);
  const [selectedDepartment, setSelectedDepartment] = React.useState();
  const [selectedProject, setSelectedProject] = React.useState();
  const [selectedAssignee, setSelectedAssignee] = React.useState();
  const [selectedCategory, setSelectedCategory] = React.useState();
  const [selectedStatus, setSelectedStatus] = React.useState();
  const [selectedPriority, setSelectedPriority] = React.useState();
  const [departments, setDepartments] = React.useState();
  const [projects, setProjects] = React.useState([]);
  const [assignees, setAssignee] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [priority, setPriority] = React.useState([]);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [fixverions, setFixverions] = React.useState([]);
  const [fileLoading, setfileLoading] = React.useState(false);
  const [defaultIssueSummary, setdefaultIssueSummary] = React.useState();
  const [issueSummary, setIssueSummary] = React.useState('');
  const [type, setType] = React.useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [uploadedFileDetails, setUploadedFileDetails] = React.useState(null);
  const [escalation,setEscalation]= React.useState();

  const handleOpenDialog = (file) => {
    const obj = {
      keyName: file.key,
    };
    getSignedUrl(obj).then((r) => {
      setSelectedFile(r);
      setType(file.path.split('.').pop())
      setUploadedFileDetails(file)
      setOpenDialog(true);
    })
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedFile(null);
  };
  
  const getIssueValue = (event) => {
    setIssueSummary(event.target.value);
  };
  const navigate = useNavigate();

  const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-size: 1.5rem;
  `;
  const FormContainer = styled.div`
    display: grid;
    grid-template-columns: ${(props) => (props.divide ? "50% 48.4%" : "100%")};
    padding: 1rem 1rem 0 1rem;
    gap: 1rem;
  `;
  const MyErrorMessage = styled.div`
    color: red;
    font-size: 13px;
  `;

  const validationSchema = Yup.object().shape({
    storyPoints: Yup.number()
      .max(20, 'Story Points can not be more than 20 numbers long'),
  });
  const onSubmit = (values) => {
    const reqBody = {
      departmentId: selectedDepartment,
      projectId: selectedProject,
      assigneeId: selectedAssignee,
      category: selectedCategory,
      statusId: selectedStatus,
      priority: selectedPriority,
      fixVersion: values.fixVersion,
      issueDetails: values.issueDetails,
      issueSummary: issueSummary,
      storyPoints: values.storyPoints,
      files: selectedFiles,
    };
console.log(reqBody)

    
    createTicket(reqBody).then((resp) => {
      if (resp?.status === false) {
        return toast.error(resp.error)
      } else {
        toast.success(Strings.CREATED_SUCCESSFULLY)
        return navigate("/my-reported-tickets");
      }
    });
  };
  const initialValues = {};
  const onClickClose = () => {
    navigate("/");
  };
  useEffect(async() => {
    getMasterDropdownData().then((resp) => {
      if (resp?.status === false) {
        return toast.error(resp.error)
      } else {
        setCategory(resp?.data?.ticketCategory);
        //setPriorityOption(resp?.data?.ticketPriorites);
        setDepartments(resp?.data?.currentUserDepartments);
        setProjects(resp?.data?.currentUserProjects);
        setAssignee(resp?.data?.agents);
        // setStatus(resp?.data?.activeStatus);
        setPriority(resp?.data?.ticketPriorites);
      }
    });
  }, []);

  const handleDepartmentChange = async (event) => {
    setSelectedDepartment(event.target.value);
    await getStatusByDepId(event.target.value);
    await getEscalationByDepartmentId(event.target.value);

  };
  const getStatusByDepId = async (departmentId) => {
    await getStatusByDepartment({ departmentId }).then(async(response) => {
      setStatus(response.data);
    })
  }
  const getEscalationByDepartmentId=async(departmentId)=>{
    await getEscalationByDepartment({ "departmentId":departmentId }).then(async(response) => {
      setEscalation(response);
      if(response?.l1_id){
        setSelectedAssignee(response.l1_id);
      }
    }) 
  }
  
  const handleProjectChange = (event) => {
    setSelectedProject(event.target.value);
  };
  const handleAssigneeChange = (event) => {
    setSelectedAssignee(event.target.value);
  };
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };
  const handlePriorityChange = (event) => {
    setSelectedPriority(event.target.value);
  };
  const onChangeFile = (event) => {
    setfileLoading(true);
    if (!event?.target?.files[0]) {
      return null;
    }
    let data = new FormData();
    data.append("file", event?.target?.files[0]);
    fileUpload(data).then((resp) => {
      if (resp?.status === false) {
        return toast.error(resp.error)
      } else {
        setSelectedFiles([...selectedFiles, resp.data]);
        setfileLoading(false);
      }
    });
  };
useEffect(()=>{
  if(selectedProject){
    getFixVersionByProject({project_id:[selectedProject]}).then((data)=>{
      setFixverions(data?.data)
    })
  }
},[selectedProject])
  return (
    <>
      <div>
        <Card elevation={3} sx={{ pt: 0, mb: 0, minHeight: "50vh" }}>
          <HeaderTitle>
            <div>Create Ticket</div>
            <div onClick={onClickClose}>
              <Icon
                className="cancel-button"
                sx={{
                  color: "#59B691",
                  fontSize: "35px !important",
                  cursor: "pointer",
                }}
              >
                cancelsharp
              </Icon>
            </div>
          </HeaderTitle>
          <Divider />
          <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormContainer>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel required={true} id="department">
                          Department
                        </InputLabel>
                        <Select
                          MenuProps={MenuProps}
                          labelId="department"
                          id="department"
                          // required={true}
                          value={selectedDepartment}
                          label="Department"
                          onChange={handleDepartmentChange}
                          defaultValue={selectedDepartment}
                        >
                          {departments?.filter(department=>department.is_active)?.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.id}>
                                {d.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel required={true} id="project">
                          
                          Project
                        </InputLabel>
                        <Select
                          MenuProps={MenuProps}
                          labelId="project"
                          id="project"
                          // required={true}
                          value={selectedProject}
                          label="Project"
                          onChange={handleProjectChange}
                          defaultValue={selectedProject}
                        >
                          {projects?.filter((project)=>project.is_active === 1).map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.id}>
                                {d.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel required={true} id="assignee">
                          Assignee
                        </InputLabel>
                        <Select
                          MenuProps={MenuProps}
                          labelId="assignee"
                          id="assignee"
                          // required={true}
                          value={selectedAssignee}
                          label="Assignee"
                          onChange={handleAssigneeChange}
                          defaultValue={selectedAssignee}
                        >
                          {assignees?.filter(assignee=>assignee.is_active)?.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.id}>
                                {d.first_name} {d.last_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel required={true} id="category">
                          Category
                        </InputLabel>
                        <Select
                          MenuProps={MenuProps}
                          labelId="category"
                          id="category"
                          // required={true}
                          value={selectedCategory}
                          label="Category"
                          onChange={handleCategoryChange}
                          defaultValue={selectedCategory}
                        >
                          {category?.map((d) => {
                            return (
                              <MenuItem key={d} value={d}>
                                {d}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel required={true} id="status">
                          Status
                        </InputLabel>
                        <Select
                          MenuProps={MenuProps}
                          labelId="status"
                          id="status"
                          // required={true}
                          value={selectedStatus}
                          label="Status"
                          onChange={handleStatusChange}
                          defaultValue={selectedStatus}
                        >
                          {status?.filter(data=>data.is_active).map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.id}>
                                {d.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <FormControl fullWidth>
                        <InputLabel required={true} id="priority">
                          Priority
                        </InputLabel>
                        <Select
                          MenuProps={MenuProps}
                          labelId="priority"
                          id="priority"
                          // required={true}
                          value={selectedPriority}
                          label="Priority"
                          onChange={handlePriorityChange}
                          defaultValue={selectedPriority}
                        >
                          {priority?.map((d) => {
                            return (
                              <MenuItem key={d} value={d}>
                                {d}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                    <FormControl fullWidth>
                    <InputLabel id="fixVersion">
                          Fix version
                        </InputLabel>
                      <Select 
                        MenuProps={MenuProps}
                        fullWidth
                        size="large"
                        name="fixVersion"
                        type="text"
                        label="Fix Version"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.fixVersion}
                        onChange={handleChange}
                        sx={{ mb: 1.5 }}
                      >
                        {fixverions?.filter(data=>data.is_active)?.map((d, i) => {
                            return (
                              <MenuItem key={i} value={d.id}>
                                {d.fix_version}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        size="large"
                        name="storyPoints"
                        type="number"
                        label="Story Points"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.storyPoints}
                        inputProps={{ min: 1 }}
                        onChange={handleChange}
                        error={Boolean(errors.storyPoints && touched.storyPoints)} 
                        helperText={touched.storyPoints && errors.storyPoints}
                        sx={{ mb: 1.5 }}
                        InputLabelProps={{
                          shrink: true,
                      }}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        size="large"
                        // required={true}
                        name="issueDetails"
                        type="text"
                        label="Issue Name"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.issueDetails}
                        onChange={handleChange}
                        sx={{ mb: 1.5 }}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <InputLabel>Issue Description</InputLabel>
                        <ReactTrixRTEToolbar
                          toolbarId="react-trix-rte-editor"
                          disableGroupingAction
                          toolbarActions={[
                            'bold',
                            'italic',
                            'strike',
                            'heading1',
                            'code',
                            'bullet',
                            'number',
                          ]}
                        />
                        <ReactTrixRTEInput
                          toolbarId="react-trix-rte-editor"
                          placeholder="Issue Description"
                          defaultValue={issueSummary}
                          onBlur = {getIssueValue}
                          className="trix-editor"
                        />
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextField
                        fullWidth
                        size="large"
                        name="files"
                        type="file"
                        variant="outlined"
                        onBlur={handleBlur}
                        onChange={onChangeFile}
                        sx={{ mb: 1.5 }}
                        value=""
                      />
                      <br></br>                     
                      {fileLoading && 
                      <div style={{position: 'fixed',backgroundColor: '#00000075',width:'100%',top:'0',left:'0',zIndex:'999',height:'100vh'}}>
                      <CircularProgress ></CircularProgress>                                          
                      </div>  }

                      {selectedFiles.map((f, index) => {
                        return (
                          <Fragment>
                            <div id={f.id}>
                              <span>{f.original_name}</span>
                              <span
                                onClick={(e) => {
                                  const obj = {
                                    uploadId: f.id,
                                    keyName: f.key,
                                  };
                                  deleteFile(obj).then((r) => {
                                    let files = selectedFiles.filter(
                                      (_, i) => i !== index
                                    );
                                    setSelectedFiles(files);
                                  });
                                }}
                              >
                                <Icon className="icon deleteIcon">delete</Icon>
                              </span>
                              <span onClick={(e) => handleOpenDialog(f)}>
                                <Icon className="icon deleteIcon">preview</Icon>
                              </span>
                            </div>
                          </Fragment>
                        );
                      })}
                      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg">
                        <DialogTitle>File Preview</DialogTitle>
                        <DialogContent>
                          {selectedFile && (
                            <>
                              {(type === 'png' || type === 'jpg' || type === 'webp') && (
                                <ReactImageMagnify
                                  {...{
                                    smallImage: {
                                      alt: 'Preview',
                                      src: selectedFile.data,
                                      width: 600,
                                      height: 400,
                                    },
                                    largeImage: {
                                      src: selectedFile.data,
                                      width: 1200,
                                      height: 800,
                                    },
                                    enlargedImagePosition: 'over',
                                    isHintEnabled: true,
                                  }}
                                />
                              )}
                              {type === 'mp4' && (
                                <video src={selectedFile.data} width="600" height="400" controls />
                              )}
                              {type === 'pdf' && (
                                <iframe
                                  src={`https://docs.google.com/gview?url=${encodeURIComponent(selectedFile.data)}&embedded=true`}
                                  title="PDF Preview"
                                  width="900px"
                                  height="700px"
                                />
                              )}
                            </>
                          )}
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCloseDialog} color="primary">
                            Close
                          </Button>
                        </DialogActions>
                      </Dialog> 
                    </Grid>
                    {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                                            {
                                                selectedFiles.map((f) => {
                                                    return (
                                                        <div id={f.id}>
                                                            <span>{f.original_name}</span>
                                                            <span onClick={deleteFile(f)}><Icon className="icon deleteIcon">delete</Icon></span>
                                                        </div>
                                                    )

                                                })
                                            }
                                        </Grid> */}
                  </Grid>
                </FormContainer>
                <div className="d-flex justify-content-end">
                  <LoadingButton
                    className="theme-color-dark-blue"
                    type="submit"
                    color="primary"
                    variant="contained"
                    sx={{
                      my: 2,
                      top: "60",
                      marginRight: "10px",
                      marginTop: "5vh",
                    }}
                  >
                    Submit
                  </LoadingButton>
                </div>
              </form>
            )}
          </Formik>
        </Card>
      </div>
    </>
  );
};

export default CreateTicket;

import React from 'react'
import { useNavigate } from 'react-router'
import { Formik } from 'formik';
import { Card, Checkbox, Divider, FormControlLabel, Icon, TextField, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import styled from '@emotion/styled'
import { LoadingButton } from '@mui/lab'
import { Strings } from 'config/strings'
import { getMasterDropdownData } from 'app/services/adminService';
import '../../style.css'
import { toast } from "react-toastify";
import { MenuProps } from 'app/utils';
import { createEmployeeLeave, createLeave } from 'app/services/leaveService';
import moment from 'moment';

const MarkEmployeeLeave = ({ onClose }) => {
    const handleClose = (event) => !!onClose && onClose(event)
    const navigate = useNavigate();
    const [selectedLeaveType, setSelectedLeaveType] = React.useState();
    const [selectedProject, setSelectedProject] = React.useState();
    const [selectedApprovedBy, setSelectedApprovedBy] = React.useState();
    const [selectedEmployee, setSelectedEmployee] = React.useState();
    const [projects, setProjects] = React.useState([]);
    const [agents, setAgents] = React.useState([]);
    const [employee, setEmployees] = React.useState([]);
    const [startDate, setStartDate] = React.useState(
        moment(new Date()).format("YYYY-MM-DD")
    );
    const [endDate, setEndDate] = React.useState(
        moment(new Date()).format("YYYY-MM-DD")
    );
    const [halfDayLeave, setHalfDayLeave] = React.useState(false);
    const [compOffLeave, setCompOffLeave] = React.useState(false);

    const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-size: 1.5rem;
`
    const FormContainer = styled.div`
    display: grid;
    grid-template-columns: ${(props) => (props.divide ? '50% 48.4%' : '100%')};
    padding: 1rem 1rem 0 1rem;
    gap: 1rem;
`
    const MyErrorMessage = styled.div`
    color: red;
    font-size: 13px;
`

    React.useEffect(async () => {
        getMasterDropdownData().then((resp) => {
            if (resp?.status === false) {
                return toast.error(resp.error)
            } else {
                setProjects(resp?.data?.currentUserProjects);
                setAgents(resp?.data?.agents);
                setEmployees([...resp?.data?.agents,...resp?.data?.users]);
            }
        });
    }, []);

    const LeaveType = [
        { name: 'Planned Leave', value: 'Planned Leave' },
        { name: 'Sick Leave', value: 'Sick Leave' },
    ]

    const onSubmit = (values) => {
        const formdata = {
            leave_date_from: startDate,
            leave_date_to: endDate,
            leaveType: selectedLeaveType,
            leaveReason: values.leaveReason,
            project_id: selectedProject,
            approvedById: selectedApprovedBy,
            halfDayLeave: halfDayLeave,
            employeeId: selectedEmployee,
            is_compOff: compOffLeave
        };

        createEmployeeLeave(formdata).then((resp) => {
            if (resp?.status === false) {
                return toast.error(resp.error);
            } else {
                toast.success("Leave Created successfully");
                return navigate('/leaves');
            }
        });
    };
    const initialValues = { };

    const handleLeaveChange = (event) => {
        setSelectedLeaveType(event.target.value);
    }
    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
    };
    const handleEmployeeChange = (event) => {
        setSelectedEmployee(event.target.value);
    };
    const handleApprovedByChange = (event) => {
        setSelectedApprovedBy(event.target.value);
    };

    const handleCheckBoxChange = (event) => {
        if (event?.target.checked) {
            setHalfDayLeave(true);
        }else{
            setHalfDayLeave(false);
        }
    }

    const handleCheckBoxChangeCompOff = (event) => {
        if (event?.target.checked) {
            setCompOffLeave(true);
        }else{
            setCompOffLeave(false);
        }
    }

    const handleStartDateChange = (e) => {
        const selectedDate = e.target.value;
        setStartDate(selectedDate);
      };

      const handleEndDateChange = (e) => {
        const selectedDate = e.target.value;
        setEndDate(selectedDate);
      };

    return (
        <>
            <div>
                <Card elevation={3} sx={{ pt: 0, mb: 0, minHeight: '50vh' }}>
                    <HeaderTitle>
                        <div>Create Employee Leave</div>
                        <div onClick={handleClose}>
                            <Icon
                                className="cancel-button"
                                sx={{
                                    color: '#59B691',
                                    fontSize: '35px !important',
                                    cursor: 'pointer',
                                }}
                            >
                                cancelsharp
                            </Icon>
                        </div>
                    </HeaderTitle>
                    <Divider />
                    <Formik
                        onSubmit={onSubmit}
                        initialValues={initialValues}
                    >
                        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <FormContainer>
                                    <Grid container spacing={2}>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                size="large"
                                                name="leaveDateFrom"
                                                type="date"
                                                label="Leave Date From"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={startDate}
                                                onChange={handleStartDateChange}
                                                sx={{ mb: 1.5 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                size="large"
                                                name="leaveDateTo"
                                                type="date"
                                                label="Leave Date To"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={endDate}
                                                onChange={handleEndDateChange}
                                                sx={{ mb: 1.5 }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                size="large"
                                                name="leaveReason"
                                                type="text"
                                                label="Leave Reason"
                                                variant="outlined"
                                                onBlur={handleBlur}
                                                value={values.leaveReason}
                                                onChange={handleChange}
                                                sx={{ mb: 1.5 }}
                                            />
                                            <br />
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel id="leave_type">Leave Type</InputLabel>
                                                <Select
                                                    MenuProps={MenuProps}
                                                    labelId="leave_type"
                                                    id="leave_type"
                                                    value={selectedLeaveType}
                                                    onChange={handleLeaveChange}
                                                    label="Session Timeout"
                                                    defaultValue={selectedLeaveType}
                                                >
                                                    {LeaveType.map(({ name, value }, idx) => (
                                                        <MenuItem value={value} key={idx}>
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel required={true} id="project">
                                                    Project
                                                </InputLabel>
                                                <Select
                                                    MenuProps={MenuProps}
                                                    labelId="project"
                                                    id="project"
                                                    // required={true}
                                                    value={selectedProject}
                                                    label="Project"
                                                    onChange={handleProjectChange}
                                                    defaultValue={selectedProject}
                                                >
                                                    {projects?.filter((project) => project.is_active === 1).map((d, i) => {
                                                        return (
                                                            <MenuItem key={i} value={d.id}>
                                                                {d.name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel required={true} id="employee">
                                                    Employee
                                                </InputLabel>
                                                <Select
                                                    MenuProps={MenuProps}
                                                    labelId="employee"
                                                    id="employee"
                                                    value={selectedEmployee}
                                                    label="Employee"
                                                    onChange={handleEmployeeChange}
                                                    defaultValue={selectedEmployee}
                                                >
                                                    {employee?.filter((a) => a.is_active === true).map((d, i) => {
                                                        return (
                                                            <MenuItem key={i} value={d.id}>
                                                                {d.first_name} {d.last_name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <FormControl fullWidth>
                                                <InputLabel required={true} id="approvedBy">
                                                    Approved By
                                                </InputLabel>
                                                <Select
                                                    MenuProps={MenuProps}
                                                    labelId="approvedBy"
                                                    id="approvedBy"
                                                    value={selectedApprovedBy}
                                                    label="Approved By"
                                                    onChange={handleApprovedByChange}
                                                    defaultValue={selectedApprovedBy}
                                                >
                                                    {agents?.filter((a) => a.is_active === true).map((d, i) => {
                                                        return (
                                                            <MenuItem key={i} value={d.id}>
                                                                {d.first_name} {d.last_name}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item lg={6} md={6} sm={12} xs={12}>
                                            <FormControlLabel
                                                control={<Checkbox checked={halfDayLeave} onChange={handleCheckBoxChange} />}
                                                label="Half day leave"
                                            />
                                              <FormControlLabel
                                                control={<Checkbox checked={compOffLeave} onChange={handleCheckBoxChangeCompOff} />}
                                                label="Is CompOff Leave"
                                            />
                                        </Grid>
                                    </Grid>
                                </FormContainer>
                                <div className='d-flex justify-content-end'>
                                    <LoadingButton
                                        className="theme-color-dark-blue"
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        sx={{ my: 2, top: "50", marginRight: "10px", marginTop: "10vh" }}
                                    >
                                        Submit
                                    </LoadingButton>
                                </div>
                            </form>
                        )}
                    </Formik>
                </Card>
            </div>
        </>
    )
}

export default MarkEmployeeLeave

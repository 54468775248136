import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import {
  approveReimbursementAndFiles,
  getReimbursementById,
  updateReimbursement,
} from "app/services/reimbursementService";
import {
  deleteFile,
  downloadFile,
  fileUpload,
  getSignedUrl,
} from "app/services/ticketService";
import { Formik } from "formik";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import ReactImageMagnify from "react-image-magnify";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import CircularProgress from "../../components/MatxLoading";
import CustomReimbursementTabs from "./customReimbursementTabs";

const ViewReimbursement = ({ onClose }) => {
  const navigate = useNavigate();
  const [selectedModeOfPayment, setSelectedModeOfPayment] = React.useState("");
  const [initialValues, setInitialValues] = React.useState();
  const [editData, setEditData] = React.useState({});
  const [fileLoading, setfileLoading] = useState(false); // To show loading while uploading files
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [uploadedFileDetails, setUploadedFileDetails] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [editEnabled, setEditEnabled] = useState(false);
  const [checkedFiles, setCheckedFiles] = useState(new Set());
  const [attachmentsPresent, setAttachmentsPresent] = useState(false);


  const HeaderTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-size: 1.5rem;
  `;
  const FormContainer = styled.div`
    display: grid;
    grid-template-columns: ${(props) => (props.divide ? "50% 48.4%" : "100%")};
    padding: 1rem 1rem 0 1rem;
    gap: 1rem;
  `;

  const handleOpenDialog = (file) => {
    const obj = {
      keyName: file.key,
    };
    getSignedUrl(obj).then((r) => {
      setSelectedFile(r);
      setType(file.path.split(".").pop());
      setUploadedFileDetails(file);
      setOpenDialog(true);
    });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedFile(null);
  };

  const updateReimbursementDetails = (value, fieldType) => {
    if (!editEnabled) {
      return;
    }
    let reqBody = {
      field: fieldType,
      id: editData.id,
    };

    switch (fieldType) {
      case "purposeOfBill":
        if (editData.purpose_of_bill === value) {
          return null;
        }
        reqBody.purposeOfBill = value;
        break;
      case "amount":
        if (editData.amount === value) {
          return null;
        }
        reqBody.amount = value;
        break;
      case "billDate":
        if (editData.bill_date === value) {
          return null;
        }
        reqBody.billDate = value;
        break;
      case "modeOfPayment":
        if (editData.mode_of_payment === value) {
          return null;
        }
        reqBody.modeOfPayment = value;
        break;

      case "files":
        reqBody.files = value;
        break;
      default:
        return;
    }

    updateReimbursement(reqBody).then((resp) => {
      if (resp?.status === false) {
        return toast.error(resp.error);
      } else {
        reimbursementDetails(editData.id);
        return toast.success("Updated Successfully");
      }
    });
  };
  const onClickClose = () => {
    navigate("/");
  };

  useEffect(() => {
    const url = window.location.href;
    const id = url.split("/").pop();
    reimbursementDetails(id);

    let storedUser = JSON.parse(localStorage.getItem("user"));
    setUser(storedUser);
  }, []);

  const reimbursementDetails = (id) => {
    getReimbursementById({ id: id }).then(async (resp) => {
      if (resp?.status === false) {
        return toast.error(resp.error);
      } else {
        setEditData(resp.data.data);
        setSelectedModeOfPayment(resp.data.data.mode_of_payment);

        let billDate;
        if (resp?.data.data?.bill_date !== null) {
          billDate = moment(resp.data.data.bill_date).format("YYYY-MM-DD");
        }
        setInitialValues({
          purposeOfBill: resp?.data.data?.purpose_of_bill
            ? resp.data.data.purpose_of_bill
            : "",
          amount: resp?.data.data?.amount ? resp.data.data.amount : "",
          billDate: billDate,
        });
        setLoading(false);
        setAttachmentsPresent(resp.data.data.reimbursementFiles.length > 0);
      }
    });
  };

  const updateFileApprovalStatus = (fileId, updatedStatus) => {
    // Find the file in the state using the fileId
    const fileToUpdate = editData.reimbursementFiles.find(
      (file) => file.id === fileId
    );
  
    if (fileToUpdate) {
      // Update the is_approved field of the file object
      fileToUpdate.is_approved = updatedStatus;
    }
  };

  const handleModeOfPaymentChange = (event) => {
    setSelectedModeOfPayment(event.target.value);
    updateReimbursementDetails(event.target.value, "modeOfPayment");
  };

  const onChangeFile = (event) => {
    setfileLoading(true);
    if (!event?.target?.files[0]) {
      return null;
    }
    let data = new FormData();
    data.append("file", event?.target?.files[0]);
    fileUpload(data).then((resp) => {
      if (resp?.status === false) {
        setfileLoading(false);
        return toast.error(resp.error);
      } else {
        updateReimbursementDetails(resp.data, "files");
        setfileLoading(false);
      }
    });
  };

  const handleApprovalStatus = (statusMessage) => {
  
    let updatedStatus = statusMessage === "Approved" ? "Approved" : "Rejected";
    let statusToUpdate = getFileStatus();
    const checkedFilesData = editData.reimbursementFiles.filter((file) =>
      checkedFiles.has(file.id)
    );

    if (!attachmentsPresent) {
      statusToUpdate = updatedStatus; // Replace "status" with the actual field name
   }
  
    // Update the approval status of selected files in the state
    checkedFilesData.forEach((file) => {
      updateFileApprovalStatus(file.id, updatedStatus);
    });

    const updatedData = { ...editData };
    updatedData.approval_status = updatedStatus;
  
    // Update the data state with the new status
    setEditData(updatedData);
  

    approveReimbursementAndFiles({
      userId: user.userId,
      id: editData?.created_by,
      reimbursementId: editData?.id,
      ReimbursementFilesId: printCheckedFilesData(),
      status: getFileStatus() || statusToUpdate ,
      filesStatus: statusMessage,
    }).then((resp) => {
      if (resp?.status === false) {
        return toast.error(resp.error);
      } else {
        return toast.success(resp.message);
      
      }
    });
    setCheckedFiles(new Set());
  };

  // Function to handle checkbox changes

  const getFileStatus = () => {
    if (editData.reimbursementFiles.length === 0) {
      return ""; // No files, so status is empty
    }

    let status = "";
    const hasRejectedFile = editData.reimbursementFiles.some(
      (file) => file.is_approved === "Rejected"
    );
      
    const allFilesRejected = editData.reimbursementFiles.every(
      (file) => file.is_approved === "Rejected"
    );

    const allFilesApproved = editData.reimbursementFiles.every(
      (file) => file.is_approved === "Approved"
    );
  
    const hasNullStatusFile = editData.reimbursementFiles.filter(
      (file) => file.is_approved === null || file.is_approved === ""
    );
    
    if (allFilesApproved) {
      status = "Approved"; // If all files are "Approved", set status to "Approved"
    } else if (allFilesRejected) {
      status = "Rejected"; // If all files are "Rejected", set status to "Rejected"
    } else if (hasRejectedFile) {
      status = "Pending"; // If there are any "Rejected" files, set status to "Pending"
    } else if (hasNullStatusFile.length > 0) {
      status = "Pending"; // If there are "Pending" files, set status to "Pending"
    }
  
    return status;
  };
  
  const handleCheckboxChange = (event, fileId) => {
    const isChecked = event.target.checked;
    setCheckedFiles((prevCheckedFiles) => {
      const updatedCheckedFiles = new Set(prevCheckedFiles);
      if (isChecked) {
        updatedCheckedFiles.add(fileId);
      } else {
        updatedCheckedFiles.delete(fileId);
      }
      return updatedCheckedFiles;
    });
  };

  const printCheckedFilesData = () => {
    const checkedFileData = editData.reimbursementFiles.filter((file) =>
      checkedFiles.has(file.id)
    );
    return checkedFileData.map((e) => e.id);
  };

  const allFilesApprovedOrRejected = editData.reimbursementFiles?.every(
    (file) => file.is_approved === "Approved" || file.is_approved === "Rejected"
  ) || false;

  return (
    <>
      {!loading && (
        <div>
          <Card elevation={3} sx={{ pt: 0, mb: 0, minHeight: "45vh" }}>
            <HeaderTitle>
              <div>Reimbursement-{editData?.id}</div>
              <div onClick={onClickClose}>
                <Icon
                  className="cancel-button"
                  sx={{
                    color: "#59B691",
                    fontSize: "35px !important",
                    cursor: "pointer",
                  }}
                >
                  cancelsharp
                </Icon>
              </div>
            </HeaderTitle>
            <Divider />
            <Formik
              onSubmit={updateReimbursement}
              initialValues={initialValues}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                      <FormControlLabel
                        control={
                           <Checkbox
                              checked={editEnabled}
                              onChange={(e) => setEditEnabled(e.target.checked)}
                              name="editEnabledCheckbox"
                              color="primary"
                              id="editCheckbox"
                            /> 
                        }
                        label="Enable Edit"
                        sx={{ mb: 2, ml: 2 }}
                      />


                  <FormContainer>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          size="large"
                          name="purposeOfBill"
                          type="text"
                          label="purpose of bill"
                          variant="outlined"
                          onBlur={(e) => {
                            updateReimbursementDetails(
                              e.target.value,
                              "purposeOfBill"
                            );
                          }}
                          value={values.purposeOfBill}
                          onChange={handleChange}
                          disabled={!editEnabled}
                          sx={{ mb: 1.5 }}
                        />
                        <br />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          size="large"
                          name="billDate"
                          type="date"
                          label="bill date"
                          variant="outlined"
                          onBlur={(e) => {
                            updateReimbursementDetails(
                              e.target.value,
                              "billDate"
                            );
                          }}
                          value={values.billDate}
                          onChange={handleChange}
                          disabled={!editEnabled}
                          sx={{ mb: 1.5 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          size="large"
                          name="amount"
                          type="number"
                          label="amount"
                          variant="outlined"
                          onBlur={(e) => {
                            updateReimbursementDetails(
                              e.target.value,
                              "amount"
                            );
                          }}
                          value={values.amount}
                          onChange={handleChange}
                          disabled={!editEnabled}
                          sx={{ mb: 1.5 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} sm={12} xs={12}>
                        <FormControl fullWidth variant="outlined">
                          <InputLabel id="mode-of-payment-label">
                            Mode of Payment
                          </InputLabel>
                          <Select
                            labelId="mode-of-payment-label"
                            id="mode-of-payment"
                            value={selectedModeOfPayment}
                            defaultValue={selectedModeOfPayment}
                            disabled={!editEnabled}
                            onChange={handleModeOfPaymentChange}
                            label="Mode of Payment"
                          >
                            <MenuItem value="Cash">Cash</MenuItem>
                            <MenuItem value="UPI">UPI</MenuItem>
                            <MenuItem value="BankTransfer">
                              Bank Transfer
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                          fullWidth
                          size="large"
                          name="files"
                          type="file"
                          variant="outlined"
                          onBlur={handleBlur}
                          onChange={onChangeFile}
                          disabled={!editEnabled}
                          sx={{ mb: 1.5, my: 2 }}
                          value=""
                        />
                        <div className="d-flex justify-content-end">
                          <LoadingButton
                            type="submit"
                            className="theme-color-dark-blue"
                            color="primary"
                            variant="contained"
                            id="updatebutton"
                            disabled={!editEnabled}
                            sx={{
                              my: 2,
                              top: "100",
                              marginRight: "10px",
                              marginTop: "0px",
                            }}
                          >
                            Update
                          </LoadingButton>
                        </div>
                        <Card sx={{ px: 3, py: 2, mb: 3 }}>
                          <InputLabel>Attachments</InputLabel>
                          {fileLoading && (
                            <div
                              style={{
                                position: "fixed",
                                backgroundColor: "#00000075",
                                width: "100%",
                                top: "0",
                                left: "0",
                                zIndex: "999",
                                height: "100vh",
                              }}
                            >
                              <CircularProgress></CircularProgress>
                            </div>
                          )}
                          {editData.reimbursementFiles?.map((f) => {
                            return (
                              <Fragment>
                                <div id={f.id}>
                                  {f.is_approved !== "Approved" &&
                                  f.is_approved !== "Rejected" && user.role === "admin" || user.role === "account" ? (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={checkedFiles.has(f.id)}
                                          onChange={(e) => {
                                            handleCheckboxChange(e, f.id);
                                          }}
                                          name="hasAttachments"
                                          color="primary"
                                          id="checkboxfiles"

                                        />
                                      }
                                    />
                                  ) : (
                                    <span
                                      style={{
                                        marginRight: "10px",
                                        color:
                                          f.is_approved === "Approved"
                                            ? "#466D1D"
                                            : "red",
                                      }}
                                    >
                                      {f.is_approved==="Approved" ? <Icon className="icon deleteIcon">
                                      check
                                    </Icon>:<Icon className="icon deleteIcon">
                                      close
                                    </Icon>}
                                    </span>
                                  )}
                                  <span>{f.upload.original_name}</span>
                                  <span
                                    onClick={() => {
                                      const obj = {
                                        uploadId: f.upload_id,
                                        keyName: f.upload.key,
                                      };
                                      deleteFile(obj).then(() => {
                                        reimbursementDetails(
                                          f.reimbursement_id
                                        );
                                      });
                                    }}
                                  >
                                    <Icon className="icon deleteIcon">
                                      delete
                                    </Icon>
                                  </span>

                                  <span
                                    onClick={() => {
                                      const obj = {
                                        keyName: f.upload.key,
                                      };
                                      downloadFile(obj).then((r) => {
                                        window.open(r.data, "_blank");
                                        reimbursementDetails(
                                          f.reimbursement_id
                                        );
                                      });
                                    }}
                                  >
                                    <Icon className="icon deleteIcon">
                                      file_download
                                    </Icon>
                                  </span>
                                  <span
                                    onClick={() => handleOpenDialog(f.upload)}
                                  >
                                    <Icon className="icon deleteIcon">
                                      preview
                                    </Icon>
                                  </span>
                                </div>
                              </Fragment>
                            );
                          })}
                          <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            maxWidth="lg"
                          >
                            <DialogTitle>File Preview</DialogTitle>
                            <DialogContent>
                              {selectedFile && (
                                <>
                                  {(type === "png" ||
                                    type === "jpg" ||
                                    type === "webp") && (
                                    <ReactImageMagnify
                                      {...{
                                        smallImage: {
                                          alt: "Preview",
                                          src: selectedFile.data,
                                          width: 600,
                                          height: 400,
                                        },
                                        largeImage: {
                                          src: selectedFile.data,
                                          width: 1200,
                                          height: 800,
                                        },
                                        enlargedImagePosition: "over",
                                        isHintEnabled: true,
                                      }}
                                    />
                                  )}
                                  {type === "mp4" && (
                                    <video
                                      src={selectedFile.data}
                                      width="600"
                                      height="400"
                                      controls
                                    />
                                  )}
                                  {type === "pdf" && (
                                    <iframe
                                      src={`https://docs.google.com/gview?url=${encodeURIComponent(
                                        selectedFile.data
                                      )}&embedded=true`}
                                      title="PDF Preview"
                                      width="900px"
                                      height="700px"
                                    />
                                  )}
                                </>
                              )}
                            </DialogContent>
                            <DialogActions>
                              <Button
                                onClick={handleCloseDialog}
                                color="primary"
                              >
                                Close
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Card>
                        {user.role === "admin" || user.role === "account" ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginBottom: "10px",
                              marginRight: "10px",
                            }}
                          >
                       {!attachmentsPresent ? (
                      <div>
                        <Button
                          variant="contained"
                          color="primary"
                          id="approvebutton"
                          onClick={() => {
                            handleApprovalStatus("Approved");
                          }}
                          sx={{ marginRight: "10px" }}
                          disabled={editData.approval_status !== "Pending"}
                        >
                          Approve
                        </Button>
                        <Button
                          id="rejectbutton"
                          variant="contained"
                          color="secondary"
                          onClick={() => handleApprovalStatus("Rejected")}
                          disabled={editData.approval_status !== "Pending"}
                        >
                          Reject
                        </Button>
                      </div>
                        ) : (
                          <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleApprovalStatus("Approved");
                        }}
                        sx={{ marginRight: "10px" }}
                        disabled={ checkedFiles.size === 0 || allFilesApprovedOrRejected}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleApprovalStatus("Rejected")}
                        disabled={ checkedFiles.size === 0 || allFilesApprovedOrRejected}
                      >
                        Reject
                      </Button>
                    </div>
                  )}

                          </div>
                        ) : null}
                        <Card sx={{ px: 3, py: 2, mb: 3 }}>
                          <CustomReimbursementTabs
                            reimbursementId={editData.id}
                          ></CustomReimbursementTabs>
                        </Card>
                      </Grid>
                    </Grid>
                  </FormContainer>
                </form>
              )}
            </Formik>
          </Card>
        </div>
      )}
    </>
  );
};

export default ViewReimbursement;

import React, { lazy } from "react";
import Loadable from "app/components/Loadable";
import { authRoles } from "../../auth/authRoles";
const EmployeeLeaves = Loadable(lazy(() => import("./EmployeeLeaves")));

const EmployeeLeavesRoutes = [
  {
    path: "/employeeLeaves",
    element: <EmployeeLeaves />,
    auth: authRoles.admin,
  },
];

export default EmployeeLeavesRoutes;

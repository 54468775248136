import { appRequest, RequestMethod } from '../../app/httpClient/request'
import config from '../../config/apiConfig.json'
const BASE_URL = config.general.baseUrl;

export const createReimbursement = (formdata) =>{
    return appRequest({
        method: RequestMethod.POST,
        url: BASE_URL+ config.reimbursement.createReimbursement,
        data : formdata
    })
}

export const getAllReimbursementsByLoggedInId=(queryParams)=>{
    return appRequest({
        method: RequestMethod.GET,
        url: BASE_URL + config.reimbursement.getAllReimbursementsById + queryParams,
    })
}

export const getAllReimbursements=(queryParams)=>{
    return appRequest({
        method: RequestMethod.GET,
        url: BASE_URL + config.reimbursement.getAllReimbursements + queryParams,
    })
}

export const approveReimbursement = (formdata) =>{
    return appRequest({
        method: RequestMethod.POST,
        url: BASE_URL+ config.reimbursement.approveReimbursement,
        data : formdata
    })
}


export const approveReimbursementAndFiles = (formdata) =>{
    return appRequest({
        method: RequestMethod.POST,
        url: BASE_URL+ config.reimbursement.approveReimbursementAndFiles,
        data : formdata
    })
}
export const getReimbursementById=(data)=>{
    return appRequest({
        method: RequestMethod.POST,
        url: BASE_URL + config.reimbursement.getReimbursementById,
        data:data
    })
}

export const updateReimbursement = (data) => {
    return appRequest({
        method: RequestMethod.POST,
        url: BASE_URL + config.reimbursement.update,
        data: data
    })
}

export const saveReimbursementComment = (data) => {
    return appRequest({
        method: RequestMethod.POST,
        url: BASE_URL + config.reimbursement.saveReimbursementComment,
        data: data
    })
}
export const getReimbursementComments = (data) => {
    return appRequest({
        method: RequestMethod.POST,
        url: BASE_URL + config.reimbursement.getReimbursementComments,
        data: data
    })
}
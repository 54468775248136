import {
    Box,
    styled,
    Table,
} from "@mui/material";
import MaterialTable from "material-table";
import { useEffect, useState } from "react";
import { getAnalytics} from "app/services/ticketService";
import { Modal } from "react-bootstrap";

const StyledTable = styled(Table)(() => ({
    whiteSpace: "pre",
    "& thead": {
        "& tr": { "& th": { paddingLeft: 0, paddingRight: 0 } },
    },
    "& tbody": {
        "& tr": { "& td": { paddingLeft: 0, textTransform: "capitalize" } },
    },
}));
const AnalyticsTable = styled(Table)(() => ({
    marginTop: '20px',
    whiteSpace: 'pre',
    '& small': {
        height: 15,
        width: 50,
        borderRadius: 500,
        boxShadow:
            '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
    },
    '& td:first-of-type': {
        paddingLeft: '16px !important',
    },
    '& th:first-of-type': {
        paddingLeft: '16px !important',
    },
    '& tfoot tr td div:nth-child(1)': {
        justifyContent: 'center',
        alignItems: 'center',
        flex: 'initial',
        margin: '0.5rem 0',
    },
    "th:last-child": {
        width: "55px !important",
    }
}))

const Analytics = ({ setCurrentView }) => {
    const [data, setData] = useState([]);
    const [showAgingPopup, setShowAgingPopup] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(false);
    useEffect(() => {
        fetchAnalytics()
    }, [])

    const fetchAnalytics = () => {
        getAnalytics({ "analyticsType": "Agent_Department_View" }).then((response) => {
            setData(response.data)
        })
    }
    const getTicketAgingAnalysis = (rowData) => {
        console.log(rowData);
        setSelectedRowData(rowData)
        setShowAgingPopup(true);
    }
    const onCloseAgingPopup = () => {
        setShowAgingPopup(false);
    }
    return (
        <Box width="100%" overflow="auto">
            <AnalyticsTable>
                <MaterialTable
                    title="Analytics"
                    columns={[
                        { title: "Department", field: "departmentName", cellStyle: { width: "12%" } },
                        { title: "Total Tickets", field: "totalTickets", cellStyle: { width: "12%" } },
                        { title: "Open", field: "openTickets", cellStyle: { width: "12%" } },
                        { title: "Closed", field: "closedTickets", cellStyle: { width: "12%" } },
                        { title: "Overdue", field: "overdueTickets", cellStyle: { width: "12%" } },
                        { title: "% of Open", field: "openTicketsPercent", cellStyle: { width: "12%" } },
                        { title: "% of Closed ", field: "closedTicketsPercent", cellStyle: { width: "12%" } },
                        { title: "% of Overdue ", field: "overdueTicketsPercent", cellStyle: { width: "12%" } },
                    ]}
                    data={data.map((e) => {
                        return {
                            departmentName: e.departmentName,
                            totalTickets: e.totalTickets,
                            openTickets: e.openTickets,
                            closedTickets: e.closedTickets,
                            overdueTickets: e.overdueTickets,
                            openTicketsPercent: e.openTicketsPercent,
                            closedTicketsPercent: e.closedTicketsPercent,
                            overdueTicketsPercent: e.overdueTicketsPercent,
                            overdueAging: e.overdueAging[0],
                            closedAging: e.closedAging[0]
                        }
                    })}
                    actions={[
                        {
                            icon: 'analytics',
                            tooltip: 'View Aging Analytics',
                            onClick: (event, rowData) => getTicketAgingAnalysis(rowData),
                        }
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        emptyRowsWhenPaging: false,
                        paging: false,
                        showTitle: false,
                        search: false,
                        toolbar: false,
                        pageSizeOptions: [],
                        tableLayout: 'auto',
                        maxBodyHeight: "400px",
                        sorting: false,
                        headerStyle: {
                            fontSize: '14px',
                            backgroundColor: '#222A45',
                            color: 'white',
                            fontWeight: '700',
                        },



                    }}
                />
                {showAgingPopup &&
                    <Modal show={showAgingPopup} onHide={onCloseAgingPopup} keyboard={true} backdrop="static" size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header closeButton >
                            <Modal.Title style={{ color: "#26568e" }}>{selectedRowData.departmentName} Ticket Aging Analysis</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h6>Overdue Tickets In Numbers</h6>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>0 Day</th>
                                        <th>1 Day</th>
                                        <th>2 Days</th>
                                        <th>3 Days</th>
                                        <th>4 Days</th>
                                        <th>5 Days</th>
                                        <th>6 Days</th>
                                        <th>7 Days</th>
                                        <th>{'>7'} Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {selectedRowData.overdueAging.zeroday}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.oneday}

                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.twoday}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.threeday}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.fourday}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.fiveday}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.sixday}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.sevenday}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.sevenplusday}

                                        </td>

                                    </tr>
                                </tbody>
                            </Table><br></br>
                            <h6>Overdue Tickets In %</h6>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>0 Day</th>
                                        <th>1 Day</th>
                                        <th>2 Days</th>
                                        <th>3 Days</th>
                                        <th>4 Days</th>
                                        <th>5 Days</th>
                                        <th>6 Days</th>
                                        <th>7 Days</th>
                                        <th>{'>7'} Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {selectedRowData.overdueAging.zeroday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.oneday_percentage}

                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.twoday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.threeday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.fourday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.fiveday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.sixday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.sevenday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.overdueAging.sevenplusday_percentage}

                                        </td>

                                    </tr>
                                </tbody>
                            </Table><br></br>
                            <h6>Closed Tickets In Numbers</h6>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>0 Day</th>
                                        <th>1 Day</th>
                                        <th>2 Days</th>
                                        <th>3 Days</th>
                                        <th>4 Days</th>
                                        <th>5 Days</th>
                                        <th>6 Days</th>
                                        <th>7 Days</th>
                                        <th>{'>7'} Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {selectedRowData.closedAging.zeroday}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.oneday}

                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.twoday}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.threeday}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.fourday}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.fiveday}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.sixday}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.sevenday}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.sevenplusday}

                                        </td>

                                    </tr>
                                </tbody>
                            </Table><br></br>
                            <h6>Closed Tickets In %</h6>
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th>0 Day</th>
                                        <th>1 Day</th>
                                        <th>2 Days</th>
                                        <th>3 Days</th>
                                        <th>4 Days</th>
                                        <th>5 Days</th>
                                        <th>6 Days</th>
                                        <th>7 Days</th>
                                        <th>{'>7'} Days</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {selectedRowData.closedAging.zeroday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.oneday_percentage}

                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.twoday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.threeday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.fourday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.fiveday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.sixday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.sevenday_percentage}
                                        </td>
                                        <td>
                                            {selectedRowData.closedAging.sevenplusday_percentage}

                                        </td>

                                    </tr>
                                </tbody>
                            </Table><br></br>
                        </Modal.Body>
                    </Modal>}
            </AnalyticsTable>


        </Box>
    );
};

export default Analytics;

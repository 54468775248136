import { Box, Button, Card, Grid, styled } from "@mui/material";
import { verifyEmail } from "app/services/userService";
import { useEffect , useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: 32,
  background: theme.palette.background.default,
}));

const ForgotPasswordRoot = styled(JustifyBox)(() => ({
  background: "#1A2038",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 800,
    margin: "1rem",
    borderRadius: 12,
  },
}));
  const VerifyEmail = () => {
  const navigate = useNavigate();
  const [resetToken, setResetToken] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state

  useEffect(() => {
    let currentUrl = window.location.href;
    let resetToken = currentUrl.substring(currentUrl.lastIndexOf("/") + 1);
    setResetToken(resetToken);
    console.log(resetToken);
    const handleFormSubmit = async () => {
      try {
        const response = await verifyEmail({ resetTokenId: resetToken });

        if (response.status === false) {
          return toast.error(response.error);
        } else {
          setIsEmailVerified(true);
          toast.success("Email verified successfully").then(() => {
            navigate("/session/signin");
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false); // Set isLoading to false after verifying email
      }
    };

    handleFormSubmit();
  }, [navigate]);

  return (
    <ForgotPasswordRoot>
      <Card className="card">
        <Grid container>
          <Grid item xs={12}>
            <JustifyBox p={4}>
              <img
                width="300"
                src="/assets/images/illustrations/dreamer.svg"
                alt=""
              />
            </JustifyBox>

            <ContentBox>
              {resetToken && isLoading ? (
                <div>
                  <Button fullWidth variant="contained" color="primary" disabled>
                    Verifying Email...
                  </Button>

                  <Button
                    fullWidth
                    color="primary"
                    variant="outlined"
                    onClick={() => navigate(-1)}
                    sx={{ mt: 2 }}
                  >
                    Go Back
                  </Button>
                </div>
              ) : (
                <div>
                  <Button fullWidth variant="contained" color="primary" disabled={!isEmailVerified}>
                    {isEmailVerified ? "Email Verified!" : "Verifying Email..."}
                  </Button>

                  {isEmailVerified && (
                    <Button
                      fullWidth
                      color="primary"
                      variant="outlined"
                      onClick={() => navigate("/session/signin")}
                      sx={{ mt: 2 }}
                    >
                      Click here to login
                    </Button>
                  )}
                </div>
              )}
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </ForgotPasswordRoot>
  );
};

export default VerifyEmail;
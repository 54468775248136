import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable'
import MarkEmployeeLeave from './markEmployeeLeave'
const LeaveView = Loadable(lazy(() => import('./leaveView')))


const leaveRoutes = [
    {
        path: '/leaves',
        element: <LeaveView />
    },
    {
        path: '/createEmployeeLeave',
        element: <MarkEmployeeLeave />
    }
]

export default leaveRoutes

import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable'
import { authRoles } from '../../auth/authRoles'
const LeaveManagementView = Loadable(lazy(() => import('./leaveManagementView')))

const leaveManagementRoutes = [
    {
        path: '/leaveManagement',
        element: <LeaveManagementView/>,
        authRoles: authRoles.admin
    },
]

export default leaveManagementRoutes

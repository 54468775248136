import { appRequest, RequestMethod } from "../../app/httpClient/request";
import config from "../../config/apiConfig.json";
const BASE_URL = config.general.baseUrl;

export const createLeave = (formdata) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.createLeave,
    data: formdata,
  });
};
export const createEmployeeLeave = (formdata) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.createEmployeeLeave,
    data: formdata,
  });
};

export const getAllLeaves = (queryParams) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.getAllLeaves + queryParams,
  });
};

export const getAllLeavesByTenant = (queryParams) => {
  console.log("BASE_URL::", BASE_URL);
  console.log(
    "config.leave.getAllLeavesByTenant::",
    config.leave.getAllLeavesByTenant
  );
  console.log("Query Params::", queryParams);
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.getAllLeavesByTenant + queryParams,
  });
};

export const getAllCompOffsByTenant = (queryParams) => {
  console.log("BASE_URL::", BASE_URL);
  console.log(
    "config.leave.getAllCompOffsByTenant::",
    config.leave.getAllCompOffsByTenant
  );
  console.log("Query Params::", queryParams);
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.getAllCompOffsByTenant + queryParams,
  });
};

export const getLeaveById = (data) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.getLeaveById,
    data: data,
  });
};

export const getAllLeavesBasedOnFilter = (queryParams) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.getAllLeavesBasedOnFilter + queryParams,
  });
};

export const approveLeave = (formdata) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.approveLeave,
    data: formdata,
  });
};

export const rejectLeave = (formdata) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.rejectLeave,
    data: formdata,
  });
};

export const cronJob = () => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.cronJob,
  });
};

export const approveLeaveThroughEmail = (data) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.approveLeaveThroughEmail,
    data: data,
  });
};

export const rejectLeaveThroughEmail = (data) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.rejectLeaveThroughEmail,
    data: data,
  });
};

export const createCompOff = (formdata) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.createCompOff,
    data: formdata,
  });
};

export const approveCompOffLeaveThroughEmail = (data) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.approveCompOffLeaveThroughEmail,
    data: data,
  });
};

export const rejectCompOffLeaveThroughEmail = (data) => {
  return appRequest({
    method: RequestMethod.POST,
    url: BASE_URL + config.leave.rejectCompOffLeaveThroughEmail,
    data: data,
  });
};

import { Box, Button, Card, Grid, styled } from "@mui/material";
import { approveCompOffLeaveThroughEmail } from "app/services/leaveService";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: "center",
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: 32,
  background: theme.palette.background.default,
}));

const ForgotPasswordRoot = styled(JustifyBox)(() => ({
  background: "#1A2038",
  minHeight: "100vh !important",
  "& .card": {
    maxWidth: 800,
    margin: "1rem",
    borderRadius: 12,
  },
}));
const ApproveCompOffLeave = () => {
  const navigate = useNavigate();
  const [resetToken, setResetToken] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const [message, setMessage] = useState('')

  useEffect(() => {
    let currentUrl = window.location.href;
    let resetToken = currentUrl.substring(currentUrl.lastIndexOf("/") + 1);
    setResetToken(resetToken);
    console.log(resetToken);
    const handleFormSubmit = async () => {
      try {
        const response = await approveCompOffLeaveThroughEmail({ resetTokenId: resetToken });
        if (response.status === false) {
          setMessage(response.error)
        } else {
          setMessage("You have successfully approved CompOff request")
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false); // Set isLoading to false after verifying email
      }
    };

    handleFormSubmit();
  }, [navigate]);

  return (
    <ForgotPasswordRoot>
      <Card className="card">
        <Grid container>
          <Grid item xs={12}>
            <JustifyBox p={4}>
              <img
                width="300"
                src="/assets/images/illustrations/dreamer.svg"
                alt=""
              />
            </JustifyBox>

            <ContentBox>
            <div style={{ padding: '10px', fontWeight: 'bold', borderColor: 'primary' }}>
                {message}
              </div>
            </ContentBox>
          </Grid>
        </Grid>
      </Card>
    </ForgotPasswordRoot>
  );
};

export default ApproveCompOffLeave;
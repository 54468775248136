import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import VerifyEmail from './VerifyEmail';
import ApproveLeave from './ApproveLeave';
import RejectLeave from './RejectLeave';
import ApproveCompOffLeave from './ApproveCompOffLeave';
import RejectCompOffLeave from './RejectCompOffLeave';

const NotFound = Loadable(lazy(() => import('./NotFound')));
const JwtLogin = Loadable(lazy(() => import('./JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('./JwtRegister')));
const EmployeeSignup = Loadable(lazy(() => import('./EmployeeSignup')));

const sessionRoutes = [
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/employeeSignup', element: <EmployeeSignup /> },
  { path: '/session/404', element: <NotFound /> },
  {path: '/session/verify-email/*',element:<VerifyEmail/>},
  {path: '/session/approve/*',element:<ApproveLeave/>},
  {path: '/session/reject/*',element:<RejectLeave/>},
  {path: '/session/compOffApprove/*',element:<ApproveCompOffLeave/>},
  {path: '/session/compOffreject/*',element:<RejectCompOffLeave/>},
];

export default sessionRoutes;
